var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "change-photo",
      class: { "change-photo--open": _vm.isOpen },
    },
    [
      _c(
        "RtbControlButton",
        {
          staticClass: "mobile-btn mobile-btn--top-right",
          on: {
            click: function ($event) {
              _vm.isOpen = false
            },
          },
        },
        [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("close")])],
        1
      ),
      _vm.isOpen
        ? _c("AudioVideoSettings", {
            attrs: { mic: false, sound: false },
            on: {
              onDone: function ($event) {
                _vm.isOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }