


















import { computed, defineComponent, toRefs } from "@vue/composition-api"
import { RtbControlButton } from "@/components/ui"

export default defineComponent({
  name: "MobileAVSettings",
  components: {
    RtbControlButton,
    AudioVideoSettings: () =>
      import(
        "@/components/GroupTeams/Common/AudioVideoSettings/AudioVideoSettings.vue"
      )
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props)

    const isOpen = computed({
      get() {
        return value.value
      },
      set(value) {
        emit("input", value)
      }
    })

    return {
      isOpen
    }
  }
})
